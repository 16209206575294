<template>
  <div style="color: white; text-align: center;">This is worker component</div>
</template>

<script>
import DecompositionMixin from './mixins/DecompositionMixin.js'
import CorrelationMixin from './mixins/CorrelationMixin.js'
import MachineLearningMixin from './mixins/MachineLearningMixin.js'

export default {
  mixins: [DecompositionMixin, CorrelationMixin, MachineLearningMixin],
}
</script>
