import { DECOMPOSITION_EXE_PATH } from '@/constants/paths'

const { spawn } = require('child_process')
const UNKNOW_ERROR = {'status': 'error', 'message': 'Unknown error when calling API'}
const IGNORE_ERROR = {'status': 'ignore'}

var decomposition_exe = null
var dataReturned = false

export default {
  mounted() {
    ipcRenderer.on('on-worker-initialize-decomposition', (_) => {
      this.setExecutableDecomposition()
      ipcRenderer.send('worker-return-initialize-decomposition-done')
    })
    ipcRenderer.on('on-worker-caculate-decomposition', (_, decomData) => {
      this.runExecutableDecomposition(decomData)
    })
  },
  methods: {
    setExecutableDecomposition() {
      if (!decomposition_exe) {
        decomposition_exe = spawn('decomposition.exe', { cwd: DECOMPOSITION_EXE_PATH })
      }
    },
    runExecutableDecomposition(decomData) {
      dataReturned = false
      const decompositionString = JSON.stringify(decomData)
      if (!decomposition_exe) {
        this.setExecutableDecomposition()
      }
      decomposition_exe.stdin.write(`${decompositionString}\n`)
      decomposition_exe.stdout.on('data', response => {
        try {
          response = JSON.parse(response.toString())
        } catch (e) {
          if (response.toString().trim() !== '') {
            response = UNKNOW_ERROR
          } else {
            response = IGNORE_ERROR
          }
        }
        if (!dataReturned) {
          ipcRenderer.send('worker-return-decomposition-data', response)
        }
        dataReturned = true
      })
      decomposition_exe.stderr.on('data', _ => {
        ipcRenderer.send('worker-return-decomposition-data', UNKNOW_ERROR)
      })
    },
  },
}