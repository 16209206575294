import { CORRELATION_EXE_PATH } from '@/constants/paths'

const { spawn } = require('child_process')
const UNKNOW_ERROR = {'status': 'error', 'message': 'Unknown error when calling API'}

var correlation_exe = null

export default {
  mounted() {
    ipcRenderer.on('on-worker-initialize-correlation', (e) => {
      this.setExecutableCorrelation()
      ipcRenderer.send('worker-return-initialize-correlation-done')
    })
    ipcRenderer.on('on-worker-caculate-correlation', (e, correData, keys) => {
      this.runExecutableCorrelation(correData, keys)
    })
  },
  methods: {
    setExecutableCorrelation() {
      if (!correlation_exe) {
        correlation_exe = spawn('correlation.exe', { cwd: CORRELATION_EXE_PATH })
      }
    },
    runExecutableCorrelation(correData, keys) {
      const correlationString = JSON.stringify(correData)
      const encoder = new TextEncoder();
      const keysBytes = String(encoder.encode(keys))
      if (!correlation_exe) {
        this.setExecutableCorrelation()
      }
      correlation_exe.stdin.write(`${correlationString}\n`)
      correlation_exe.stdin.write(`${keysBytes}\n`)
      correlation_exe.stdout.on('data', response => {
        try {
          response = JSON.parse(response.toString())
        } catch (e) {
          response = UNKNOW_ERROR
        }
        ipcRenderer.send('worker-return-correlation-data', response)
      })
      correlation_exe.stderr.on('data', response => {
        ipcRenderer.send('worker-return-correlation-data', UNKNOW_ERROR)
      })
    },
  },
}